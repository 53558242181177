// event-bus.ts
import { EventEmitter } from 'events';

declare global {
  interface Window { eventBus: EventEmitter }
}

function createEventBus() {
  if (window.eventBus) {
    return window.eventBus;
  }

  const eventBus = new EventEmitter();
  window.eventBus = eventBus;

  return eventBus;
}

export const eventBus = createEventBus();
