import { AIComm } from "./AIComm";
import { ChatSettingsInferenceTemplate, InferenceParams, Message, Model, TextReply } from "./Model";

export class DirectEndpointAIComm extends AIComm {
    isJson: boolean;

    constructor(name, urlBase, model, key, isJson = true) {
        super(name, urlBase, model, key);
        this.isJson = isJson;
    }

    async getAuthHeader(model: Model = null) {
        const key = 'Authorization';
        const value = `Bearer ${this.apiKey}`;
        return { key, value };
    }

    getURLwithSuffix(url, model) {
        if (url.endsWith('/')) {
            url = url.substring(0, url.length - 1);
        }
        return url;
    }

    getValueByPath(data, path) {
        let result = data;
        for (const key of path) {
            if (result != null && key in result) {
                result = result[key];
            } else {
                return undefined; // Path does not exist
            }
        }
        return result;
    }

    async callDirect(instruction: string, infTemp: ChatSettingsInferenceTemplate | null, model: Model): Promise<string> {
        const data = {
            model: model.id,
            messages: [
                { role: 'system', content: instruction }
            ],
            stream: false
        };

        const maxTokens = infTemp.inference?.max_tokens ?? 0;
        if (maxTokens > 0) {
            data['max_tokens'] = maxTokens;
        }

        const url = this.getURLwithSuffix(this.apiURL, model.id);
        const auth = await this.getAuthHeader(model);
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                [auth.key]: auth.value
            },
            body: JSON.stringify(data)
        });

        const text = await response.text();
        return text;
    }

    async call(instruction: string, msgs: Message[], infTemp: ChatSettingsInferenceTemplate | null, temperature: number, update: any, model: Model, chatId: string = null): Promise<string> {
        let data = {};
        if (model.type === 'text') {
            const inst = instruction ? instruction + ': ' : '';
            data = {
                input_data: {
                    input_string: [inst + (prompt ? prompt : '')],
                    parameters: {
                        return_full_text: false
                    }
                }
            };

            const maxTokens = infTemp.inference?.max_tokens ?? 0;
            if (maxTokens > 0) {
                data['max_new_tokens'] = maxTokens;
            }
        }
        else {
            data = {
                model: model.id,
                messages: [...msgs.map(m => { return { role: m.type, content: m.message } })],
                stream: true,
                temperature: temperature,
                chatId: chatId
            };

            const maxTokens = infTemp.inference?.max_tokens ?? 0;
            if (maxTokens > 0) {
                data['max_tokens'] = maxTokens;
            }

            if (instruction) {
                //if (this.model.indexOf('-3.5') > 0 || this.model.indexOf('-35') > 0) {
                // insert instruction into data.messages as first element
                data['messages'].unshift({ role: "system", content: instruction });
                //}
            }
        }

        const url = this.getURLwithSuffix(this.apiURL, model.id);
        const auth = await this.getAuthHeader(model);
        const headers = {
            'Content-Type': 'application/json',
            [auth.key]: auth.value
        };

        let response = null;

        //proxy here
        if (this.proxyURL) {
            const proxyURL = this.proxyURL;
            const proxyKey = this.proxyKey;
            const payload = { url: url, payload: data, key: this.apiKey, headers: headers };
            try {
                response = await fetch(proxyURL, {
                    method: 'POST',
                    headers: headers,
                    body: JSON.stringify(payload)
                });
            }
            catch (e) {
                console.error('Error calling proxy: ' + e);
                return "ERROR: " + e.message;
            }
        }
        else {
            response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data)
            });
        }

        if (response.ok) {
            if (this.proxyURL) {
                // expand response per replyPath
                try {
                    const text = await response.text();
                    const jsonData = JSON.parse(text);
                    let reply = jsonData;
                    if (model.replyPath) {
                        reply = this.getValueByPath(jsonData, JSON.parse(model.replyPath));
                    }
                    update(reply, model);
                    return reply;
                } catch (e) {
                    console.error('Error parsing response: ' + e);
                }
            }
            else {
                // const reader = response.body.getReader();
                // const textDecoder = new TextDecoder();
                // const parser = createParser((event) => {
                //   if (event.type === 'event') {
                //     var xdata = event.data.split('\n');
                //     var count = 0;
                //     for (var x of xdata) {
                //       if (x.length > 0) {
                //         try {
                //           let data = JSON.parse(x);
                //           if (count++ > 0) {
                //             data += "\n";
                //           }
                //           console.log(data);
                //           update(data, model);
                //         } catch (e) {
                //           console.error('Error parsing response: ' + e);
                //           console.log('SPECIFICALLY:' + x);
                //         }
                //       }
                //     }
                //   }
                // });

                // while (true) {
                //   const { done, value } = await reader.read();
                //   if (done) break;
                //   const textChunk = textDecoder.decode(value);
                //   console.log('value:' + textChunk);
                //   parser.feed(textChunk);
                // }
                await this.parseAndSend(response, update, model);
            }
        } else {
            console.error(`Request failed with status code ${response.status}`);
        }

        return "";
    }

    parseTextReply(text: string, model: Model): TextReply {
        try {
            const data = JSON.parse(text);
            if (this.proxyURL && model.replyPath) {
                return { text: this.getValueByPath(data, JSON.parse(model.replyPath)), isDone: false };
            }

            return data;
        }
        catch (e) {
            console.error('Error parsing response: ' + e);

            if (text.startsWith('{')) {
                // assume multiple... split by \n
                const lines = text.split('\n');
                let content = '';

                for (const line of lines) {
                    const data = JSON.parse(line);
                    if (this.proxyURL && model.replyPath) {
                        content += this.getValueByPath(data, JSON.parse(model.replyPath));
                    }
                    else {
                        content += data.choices[0].delta.content;
                    }
                }

                const data1 = JSON.parse(lines[0]);
                data1.choices[0].delta.content = content;
                return data1;
            }

            return { text: '', isDone: false };
        }
    }
}
