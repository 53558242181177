export class UrlCache {
  private static cache: Map<string, UrlCacheItem> = new Map<string, UrlCacheItem>();

  public static get(key: string): string {
    return UrlCache.cache.get(key).value;
  }

  public static set(key: string, value: any): void {
    UrlCache.cache.set(key, new UrlCacheItem(key, value));
  }

  public static has(key: string): boolean {
    return UrlCache.cache.has(key);
  }

  public static async getSet(key: string, fetch: () => Promise<string>, maxAge: number) {
    if (UrlCache.has(key)) {
      const item = UrlCache.cache.get(key);
      if (item.timestamp + (maxAge * 1000) > Date.now()) {
        return item.value;
      }
    }

    var result = null;
    try {
      UrlCache.set(key, null); // null while fetching!
      await fetch().then((value) => {
        result = value;
        UrlCache.set(key, value);
      });
    }
    catch (e) {
      console.log('Error fetching ' + key + ': ' + e);
      UrlCache.set(key, null);
    }

    return result;
  }
}

export class UrlCacheItem {
  public key: string;
  public value: any;
  public timestamp: number;

  constructor(key: string, value: string) {
    this.key = key;
    this.value = value;
    this.timestamp = Date.now();
  }
}
