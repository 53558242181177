import { createAuth0Client } from '@auth0/auth0-spa-js';
import { eventBus } from '../event-bus';

export class AuthZero {
  static instance = null;
  auth0Client = null;

  static xinit() {
    if (AuthZero.instance === null) {
      AuthZero.instance = new AuthZero();
    }

    return AuthZero.instance;
  }

  init() {
    return AuthZero.xinit();
  }

  async auth0init() {
    this.init();
    this.auth0Client = await createAuth0Client({
      domain: 'dev-szv4uy3njbh1af4y.us.auth0.com',
      clientId: 'QoKylWf11QGzTham8jMDmJdnucC8zw9H',
      authorizationParams: { redirect_uri: window.location.origin + '/chat' },
      useCookiesForTransactions: true,
    });
  }

  async authRedirected() {
    this.init();
    if (this.auth0Client == null) {
      await this.auth0init();
    }

    // Parse the authentication result
    const query = window.location.search;
    if (query.includes("code=") && query.includes("state=")) {
      await this.auth0Client.handleRedirectCallback();
      window.history.replaceState({}, document.title, "/chat");
      eventBus.emit('logged-in', { name: await this.getMyName() });
    }
    // window.setTimeout(async () => alert(await getLoggedInUserEmail()), 5000);
  }

  async auth0logout() {
    this.init();
    if (this.auth0Client == null) {
      await this.auth0init();
    }
    await this.auth0Client.logout();
  }

  async auth0login() {
    this.init();
    if (this.auth0Client == null) {
      await this.auth0init();
    }
    await this.auth0Client.loginWithRedirect({
      target: window.location.origin + '/chat'
    });
  }

  async isLoggedIn() {
    this.init();
    if (this.auth0Client == null) {
      await this.auth0init();
    }
    return await this.auth0Client.isAuthenticated();
  }

  async getMyEmail() {
    this.init();
    if (this.auth0Client == null) {
      await this.auth0init();
    }
    const user = await this.auth0Client.getUser();
    return user ? user.email : null;
  }

  async getMyName() {
    this.init();
    if (this.auth0Client == null) {
      await this.auth0init();
    }
    const user = await this.auth0Client.getUser();
    return user ? user.name : null;
  }

  async getMyUserName() {
    return await this.getMyEmail();
  }

  async logout(afterLogout: () => void) {
    this.init();
    const result = await this.auth0logout();
    afterLogout();
    return result;
  }

  //---------------------//

  async setLogged(isLoggedIn: boolean) {
    this.init();

    //nothing???
  }

  async forceLogin(afterLogin: () => void) {
    this.init();

    await this.auth0login();
    //nothing??
  }

  async getToken() {
    this.init();
    return "";
  }
}

