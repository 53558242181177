import { createParser } from "eventsource-parser";
import { ChatSettingsInferenceTemplate, InferenceParams, Message, Model, ModelSets, TextReply } from "./Model";
import { ZService } from "./ZSettingsfn";

export class AIComm {
  name: string;
  apiURL: string;
  apiKey: string;
  apiVersion: string;
  model: string;
  isConnected: boolean = false;
  wasCalled: boolean = false;
  canHandleSpecialAttachments = false;

  proxyURL: string;
  proxyKey: string;
  keyUrl?: string;

  constructor(name: string, urlBase: string, model: string, key: string) {
    this.name = name;
    this.apiURL = urlBase;
    this.apiKey = key;
    this.model = model;
  }

  addDefaults(services: ZService[]) {
    // base
  }

  async getModelsClass(service: ZService = null): Promise<Model[]> {
    // base implementation
    console.warn('getModelsClass not implemented:' + service.commType);
    const models: Model[] = [];
    this.addInServiceModels(models, service);
    models.map(m => {
      m.serviceUid = service.uid;
    });
    return models;
  }

  addInServiceModels(models: Model[], service: ZService) {
    const modelsToAdd = Model.loadModelsFromService(service, this);
    models.push(...modelsToAdd);
  }

  async callDirect(instruction: string, infTemp: ChatSettingsInferenceTemplate | null, model: Model): Promise<string> {
    throw new Error("Method not implemented.");
  }

  async call(instruction: string, msgs: Message[], infTemp: ChatSettingsInferenceTemplate | null, temperature: number, update: any, model: Model, chatId: string = null): Promise<string> {
    // base implementation
    throw new Error("Method not implemented.");
  }

  async callTTS(text: string, key: string, voiceModel: Model, voice: string = 'alloy'): Promise<string | void> {
    throw new Error("Method not implemented.");
  }

  async callListen(blob: Blob, listenModel: Model, convId: string, mode: string) {
    throw new Error("Method not implemented.");
  }

  parseTextReply(text: string, model: Model, ms = 0): TextReply {
    // base implementation
    throw new Error("Method not implemented.");
  }

  async parseAndSend(response, update, model, startTime = 0) {
    var doContinue = true;

    const parser = createParser((event) => {
      if (event.type === 'event') {
        const endTime = new Date().getTime();
        const ms = endTime - startTime;

        if (event.data !== '[DONE]') {
          const reply = this.parseTextReply(event.data, model, ms);
          if (reply) {
            // console.log(reply);
            doContinue = update(reply, model);
          }
        }
        else {
          doContinue = update({ isDone: true, ms: ms }, model);
        }
      }
    });

    if (response.body) {
      const reader = response.body.getReader();
      const textDecoder = new TextDecoder();

      while (doContinue) {
        const { done, value } = await reader.read();

        if (done) {
          console.log('DONE: ' + value);
          break;
        }

        const textChunk = textDecoder.decode(value);
        parser.feed(textChunk);
      }

      if (!doContinue) {
        const endTime = new Date().getTime();
        const ms = endTime - startTime;
        reader.cancel();
        update({ isDone: true, ms: ms }, model)
      }
    }
  }

  private getMsgFor(template: any, role: string, text: string, type: string): any {
    if (template['items']) {
      const item = template['items'].filter(i => i['role'] === role);
      if (item.length > 0) {
        const zero = item[0];
        type = zero['type'] || type;
        if (type === 'json') {
          const msg = {};
          msg[zero['roleName']] = item[0]['roleValue'];
          msg[zero['contentName']] = text;
          return msg;
        }
        else if (type === 'string') {
          let result = text;
          if (zero.default && !result) {
            result = zero.default;
          }
          if (zero.before) {
            result = zero.before + result;
          }
          if (zero.after) {
            result = result + zero.after;
          }

          if (role === 'prompt') {
            const asstItem = template['items'].filter(i => i['role'] === 'assistant');
            if (asstItem && asstItem.length > 0 && asstItem[0].isLeading && asstItem[0].before) {
              result += asstItem.before;
            }
          }

          return result;
        }
      }
    }
  }

  getInitAndUser(msgs: OAIMsg[], templateName: string = 'openai') {
    const aiTemplates = ModelSets.aiTemplates['promptTemplates'];
    if (!aiTemplates) {
      return null;
    }

    const aiMatchingTemplates = aiTemplates.filter(t => t.sid === templateName);
    const aiTemplate = aiMatchingTemplates.length > 0 ? aiMatchingTemplates[0] : null;
    if (!aiTemplate) {
      return null;
    }

    const sysMsg = msgs.filter(m => m.role === 'system');
    const hasSystem = sysMsg.length > 0;
    const users = msgs.filter(m => m.role === 'user');
    const hasHistory = users.length > 1;
    const lastUser = users.length > 0 ? users[length - 1] : null;
    const history = msgs.filter(m => (sysMsg.length === 0 || m != sysMsg[0]) && m != lastUser);
    const data = {};

    let template = null;
    if (aiTemplate['conditional_templates']) {
      for (const t of aiTemplate['conditional_templates']) {
        if (t['condition']) {
          const cond = t['condition'];
          let match = true;
          if (cond['hasHistory']) {
            match = match && cond['hasHistory'] === hasHistory;
          }
          if (cond['hasSystem']) {
            match = match && cond['hasSystem'] === hasSystem;
          }
          if (match) {
            template = t['template'];
            break;
          }
        }
      }
    }
    else {
      template = aiTemplate['template'];
    }

    if (!template) {
      return null;
    }

    //got template

    //system piece first
    const response = {};
    const type = template['type'];
    if (type === 'json') {
      response['init'] = {};
      const msgs = [];
      if (hasSystem) {
        const msgForSystem = this.getMsgFor(template, 'system', sysMsg[0].text, type);
        msgs.push(msgForSystem);
      }

      if (hasHistory) {
        for (const m of history) {
          const item = this.getMsgFor(template, m.role, m.text, type);
          msgs.push(item);
        }
      }

      response['init'][template.topKey] = msgs;
    }

    if (lastUser) {
      const promptMsg = this.getMsgFor(template, 'prompt', lastUser[0].text, type);
      response['prompt'] = promptMsg;
    }

    return response;
  }

}

export class OAIMsg {
  role: string;
  type?: string;
  text: string;
}