import { PublicClientApplication } from '@azure/msal-browser';

export let msalInstance: PublicClientApplication;

const CLIENT_ID = '491869e7-98d7-45ef-956e-2705d39904d7';
const AUTH = 'https://login.microsoftonline.com/microsoft.onmicrosoft.com';

declare global {
  interface Window { msalInstance: PublicClientApplication }
}

export const getMsalInstance = async (clientId = null, auth = null, tenantId = null): Promise<PublicClientApplication> => {
  console.log('getMsalInstance');
  const msalConfig = {
    auth: {
      authority: auth ?? AUTH,
      clientId: clientId ?? CLIENT_ID,
      redirectUri: window.location.origin,
      knownAuthorities: [tenantId ?? ''],
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false,
    },
  };

  msalInstance = new PublicClientApplication(msalConfig);
  await msalInstance.initialize();

  await msalInstance.handleRedirectPromise()
    .then((response) => {
      if (response) {
        console.log('Redirect response', response);
      }
    })
    .catch((error) => {
      console.error('Redirect error', error);
    });

  console.log('Msal.PublicClientApplication', msalInstance);
  window.msalInstance = msalInstance;

  return msalInstance;
};