import { Speech } from "global/speech";
import { toggleClass } from "helpers/utils";
import { getMsalInstance } from "global/msal";
import { XMgr } from "global/x-mgr/x-mgr";
import { XService } from "global/openai/xservice";
import { Model } from "global/_zcom/Model";
import { ZSettings } from "global/_zcom/ZSettingsfn";

declare global {
  interface Window { DataMgr: DataMgr }
}

export class DataMgr {
  static instance: DataMgr = null;
  static IsLoaded: boolean = false;
  static speechInit: boolean = false;

  basebaseUrl: string = '$api$';

  events: any = {};
  public settings: Settings = null;

  currentRoute: string = '';
  static speech: Speech;

  constructor() {
    if (DataMgr.instance === null || typeof DataMgr.instance === 'undefined') {
      DataMgr.instance = this;
      window.DataMgr = this;

      DataMgr.onEvent('data-manager', 'onLoad', {
        fn: async () => {
          await (await DataMgr.get()).getStartup();
        }
      });

      DataMgr.onEvent('data-manager', 'route-changed', {
        fn: async (detail) => {
          this.routeChanged(detail);
        }
      });

      this.loadSettings();
    }
  }

  async getStartup() {
    //this.rulesData = await getUrl(this.baseUrl) as Rule[];

    DataMgr.doEvent('got-startup-data');
  }

  static topLoadSettings() {
    if (DataMgr.instance === null) {
      new DataMgr();
    }

    DataMgr.instance.loadSettings();
  }

  routeChanged(data: any) {
    var route = data.route.toLowerCase();

    if (this.currentRoute !== route) {
      DataMgr.IsLoaded = false;
      DataMgr.doEvent('loading-change');
      this.currentRoute = route;

      var coreRoute = '';
      var prefs = ['/detail', '/edit'];
      for (var i = 0; i < prefs.length; i++) {
        if (route.indexOf(prefs[i]) === 0) {
          coreRoute = prefs[i];
          route = route.substring(prefs[i].length + 1);
        }
      }

      if (coreRoute === '/detail') {
      } else if (coreRoute === '/edit') {
      } else {
        DataMgr.IsLoaded = true;
        DataMgr.doEvent('loading-change');
      }
    }
  }

  loadSettings() {
    //Settings-2: set here
    var defaultSettings = {
      creator: "", darkMode: true
    };

    try {
      this.settings = JSON.parse(localStorage.getItem('settings')) as Settings;

      if (this.settings === null) {
        this.settings = defaultSettings;
      }
    } catch {
      this.settings = defaultSettings;
    }

    var ionApp = document.getElementsByTagName('ion-app');
    //HACK: REENABLE
    // if (ionApp && ionApp.length > 0) {
    //   toggleClass(ionApp[0] as Element, 'nightmode', this.settings.darkMode);
    //   toggleClass(ionApp[0] as Element, 'daymode', !this.settings.darkMode);
    // }
  }

  saveSettings() {
    try {
      localStorage.setItem('settings', JSON.stringify(this.settings));
    } catch {
      console.log('could not save settings');
    }
  }

  static sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  static async get() {
    if (DataMgr.instance === null || typeof DataMgr.instance === 'undefined') {
      if (window.DataMgr) {
        DataMgr.instance = window.DataMgr;
      }
      else {
        new DataMgr();
      }
    }

    return DataMgr.instance;
  }

  //--events--------------------------//

  static async onEvent(source: string, name: string, data: any) {
    var inst = await DataMgr.get();
    var eventList = inst.events[name] || [];
    var single = eventList.indexOf(e => e.source === source);
    data.source = source;
    if (single > -1) {
      eventList.splice(single, 1);
    }
    eventList.push(data);
    inst.events[name] = eventList;
    //log('added:' + name + " from " + source, 'events', 'data-manager');
  }

  public async onEventLocal(source: string, name: string, data: any) {
    var inst = this;
    var eventList = inst.events[name] || [];
    var single = eventList.indexOf(e => e.source === source);
    data.source = source;
    if (single > -1) {
      eventList.splice(single, 1);
    }
    eventList.push(data);
    inst.events[name] = eventList;
    //log('added:' + name + " from " + source, 'events', 'data-manager');
  }

  static async doEvent(name: string, data: any = null) {
    var inst = await DataMgr.get();
    var eventList = inst.events[name] || [];
    for (var i = 0; i < eventList.length; i++) {
      if (typeof eventList[i].fn === 'function') {
        if (data === null) {
          eventList[i].fn()
        }
        else {
          eventList[i].fn(data);
        }
      }
    }
    //log('fired:' + name, 'events', 'data-manager');
  }

  // --- speech --- //
  static listen() {
    if (!this.speechInit) {
      this.speechInit = true;
      DataMgr.speech = new Speech();
    }

    const key = localStorage.getItem('azure-key');
    const region = localStorage.getItem('azure-region');
    const speechkeyword = localStorage.getItem('speechkeyword');
    if (key && region && speechkeyword) {
      DataMgr.speech.start(key, region, speechkeyword);
    }
  }

  static async sayOpenAI(text, voice) {

  }

  static async say(text, voice, voiceModel: Model) {
    await
      Model.say(voiceModel, text, voice);

    // if (voice === null) {
    //   if (voiceModel.owner === 'openai') {
    //     voice = 'nova'; // XService.isOpenAI() ? 'nova' : 'en-US-SaraNeural';
    //   }
    // }

    // if (!DataMgr.speech) {
    //   this.speechInit = true;
    //   DataMgr.speech = new Speech();
    // }

    // const key = localStorage.getItem('azure-key');
    // const region = localStorage.getItem('azure-region');

    // // switch services
    // // xxx
    // const service = XService.getService();
    // if (service == 'openai') {
    //   const openAiKey = localStorage.getItem('openai-key') ?? '';
    //   const voice = XService.getVoice().toLocaleLowerCase();
    //   await DataMgr.speech.sayThruOpenAI(text, openAiKey, voice);
    //   return;
    // }

    // if (key && region) {
    //   await DataMgr.speech.sayThruSSML(text, key, region, voice);
    // }
  }

  static async sayToUrl(text, voice): Promise<string> {
    if (voice === null) {
      voice = XService.isOpenAI() ? 'nova' : 'en-US-SaraNeural';
    }

    if (!DataMgr.speech) {
      this.speechInit = true;
      DataMgr.speech = new Speech();
    }

    const key = localStorage.getItem('azure-key');
    const region = localStorage.getItem('azure-region');

    // switch services
    // xxx
    const service = XService.getService();
    if (service == 'openai') {
      const openAiKey = localStorage.getItem('openai-key') ?? '';
      const voice = XService.getVoice().toLocaleLowerCase();
      return await DataMgr.speech.sayThruOpenAIToUrl(text, openAiKey, voice);
    }

    if (key && region) {
      await DataMgr.speech.sayThruSSML(text, key, region, voice);
    }

    return null;
  }

  static async sayFromUrl(url: string) {
    if (!DataMgr.speech) {
      this.speechInit = true;
      DataMgr.speech = new Speech();
    }

    await DataMgr.speech.sayFromUrl(url);
  }

  static async sayDirectSSML(ssml: string) {
    if (!DataMgr.speech) {
      this.speechInit = true;
      DataMgr.speech = new Speech();
    }

    const key = localStorage.getItem('azure-key');
    const region = localStorage.getItem('azure-region');
    await DataMgr.speech.sayWithSSML(ssml, key, region);
  }

  static build(text, voice) {
    if (voice === null) {
      voice = 'en-US-SaraNeural';
    }

    if (!DataMgr.speech) {
      this.speechInit = true;
      DataMgr.speech = new Speech();
    }

    const key = localStorage.getItem('azure-key');
    const region = localStorage.getItem('azure-region');
    if (key && region) {
      return DataMgr.speech.buildThruSSML(text, key, region, voice);
    }

    return '';
  }

  static wrap(ssml: string) {
    if (!DataMgr.speech) {
      this.speechInit = true;
      DataMgr.speech = new Speech();
    }

    return DataMgr.speech.wrapSSML(ssml);
  }

  static stopSay() {
    if (this.speechInit && DataMgr.speech) {
      DataMgr.speech.stopSpeech();
    }
  }

  static hasCog() {
    return localStorage.getItem('azure-key') && localStorage.getItem('azure-region');
  }

  static hasVoice() {
    return true;
    //return (XService.isAzureOpenAI() && localStorage.getItem('azure-key') && localStorage.getItem('azure-region'))
    //  || (XService.isOpenAI() && localStorage.getItem('openai-key'));
  }

  public static switchUIMode(): UIMode {
    return localStorage.getItem('uimode') ? (localStorage.getItem('uimode') === 'simple' ? UIMode.Simple : UIMode.Advanced) : UIMode.Simple;
  }

  static msalI = null;

  public static getMyName(clientId = null, auth = null) {
    if (this.msalI === null) {
      this.msalI = getMsalInstance(clientId, auth);
    }

    return this.msalI.account?.name;
  }
}
// -------------------------------------------- //

export interface Settings {
  darkMode: boolean;
  creator: string;
}

export interface Command {
  name: string;
  description: string;
  id: string;
  prompt: string;
  stop: string[] | null;
  truncate: string[] | null;
  url: string;
  addLineNumbers: boolean | null;
  injectResponses: string | null;
  hideSourceLang: boolean | null;
  hideTargetLang: boolean | null;
  sourceInput: string | null;
  targetOutput: string | null;
  placeholder: string | null;
  instructions: string | null;
  past: string | null;
  toggleSourceInput: boolean | null;
  toggleSourceInputTitle: string;
  promptWithSourceInput: string;
  sourceInputTitle: string | null;
  sourcecodeDefault: string | null;
  canSave: boolean | null;
  canHear: boolean | null;
  canSpeak: boolean | null;
}

export interface Language {
  language: string;
  singleLineComment: string;
  aka: string[] | null;
}

export interface Pair {
  source: string;
  target: string;
}

export interface LineText {
  line: number;
  text: string;
}

export interface SavedPrompt {
  id: number;
  pageId: string;
  prompt: string;
  code: string;
}

export interface ChatSet {
  pathId: string;
  instruction: string;
  prompt: string;
  response: string;
  model: string;
}

export interface ChatDuo {
  user: string;
  bot: string;
  isError?: boolean;
  isUserEditable?: boolean;
  isBotEditable?: boolean;
  function?: ChatFunction; // function response from model
  fnToSend?: ChatFunction; // function to send to model
  pluginResponse?: string;
  _isComplete: boolean;
  _overrideUser?: string;
  _isDebug?: boolean;
}

export interface ChatFunction {
  name: string;
  arguments: string;
  response?: string;
  _isResponseSubmitted?: boolean;
}

export interface XChatSets {
  [chatName: string]: XChatSet;
}

export interface XChatSet {
  inst?: string;
  chats: ChatDuo[];
}

export interface SavedPromptX {
  name: string;
  prompt: string;
  voice?: string;
}

export interface SavedChatX {
  name: string;
  prompt: string;
  voice?: string;
}

export class ChainLink {
  order: number;
  id: string;
  name: string;
  action: string;
}

export class ChatSetupCard {
  id: string;
  name: string;
  type: string; //audience, goal, assist, style
  order: number; // within type, to display
  isActive: boolean;
  prompt: string;
}

export class EditItem {
  label: string;
  value: string | boolean;
  type?: string | undefined;
  id?: string;
}

export class ChatCategory {
  id: string;
  name: string;
  order?: number;
  description?: string;
  starter?: string;
}

export class ChatFunctionCard {
  id: string;
  name: string;
  description: string;
  parameters: ChatFunctionParameter[];

  api: ChatFunctionAPI;

  isActive: boolean;
}

export class ChatFunctionParameter {
  key: string;
  type: string;
  description?: string;
  enumValues?: string[];
  isRequired: boolean;
}

export class ChatFunctionAPI {
  url: string; //the url with {key} for the parameter embedded (i.e. https://api.github.com/users/{key})
  method: string; //get, post, put, delete
  postProcessing: ChatFunctionAPIPostProcessing[];
}

export class ChatFunctionAPIPostProcessing {
  index: number; //the order to run the post processing
  type: string; //json, regex, text, html
  key: string; //if json, the key to use. if regex, the regex to use. if html, the selector to use
  value: string; //if json, the value to use. if regex, the replacement to use. if html, the attribute to use
}

export class Voice {
  name: string;
  extra: string;
}

export enum UIMode {
  Simple,
  Advanced,
  Hidden
}

export class ChatPrompt {
  userId: number;
  promptId: number;
  name: string;
  description?: string;
  prompt: string;
  data?: string;
  promptDisable?: Date | undefined;
  promptDelete?: Date | undefined;
  dataDelete?: Date | undefined;
  guid?: string;
  promptVisible: boolean;
  dataVisible: boolean;
  dataEditable: boolean;
  dataRequired: boolean;
  instructions?: string;
  dataLabel?: string;
  directConversation: boolean;
  recordRequests: boolean;
  recordResponses: boolean;
  recordData: boolean;
  isSharable: boolean;
  promptUpdated?: Date | undefined;
  promptDeleted?: Date | undefined;
  promptDisabled?: Date | undefined;
  daysToDeleteUserData?: number | undefined;
  daysToDeleteUserHistory?: number | undefined;
  functions?: string;
  promptCreated?: Date | undefined;
  model?: string | undefined;

  voice?: string | undefined;
}

export class ChatPromptHolder {
  prompt?: ChatPrompt;
  errorId?: number = 0;
  error?: string = null;
}

export class CCX_HistoryMainRecord {
  userId: number;
  chatId: string;
  created: Date;
  title?: string;
}

export class CCX_HistoryItemsRecord {
  userId: number;
  chatId: string;
  seqId: number;
  request?: string;
  functionRequest?: string;
  functionResponse?: string;
  response?: string;
  instructions?: string;
  created?: Date;
  variantId?: number;
  pluginName?: string;
  pluginResponse?: string;
}

export class CCX_PluginsRecord {
  id: string;
  type: number;
  name: string;
  description?: string;
  primaryPrompt?: string;
  addPrompt?: string;
  functions?: string;
  isVisible: boolean;
  responseAddPrompt?: string;
  remoteCall?: string; //json string of CCX_RemoteCall
  replaceUserPrompt?: string;
  appendContext?: string;
  notices?: string;
  _stage?: PluginStage;
  _stage1Convo?: ChatDuo;
  _notice?: CCX_PluginNotice;
}

export class CCX_PluginNotice {
  footer?: CCX_PluginNoticeItem[];
  saveData?: boolean;
}

export class CCX_PluginNoticeItem {
  text: string;
  link?: string;
}

export enum PluginStage {
  Unused,
  AddPromptSubmitted,
  AddPromptResponseReceived,
  PluginRemoteCalled,
  PluginRemoteReceived,
  ResponseAddPromptSubmitted,
  ResponseAddPromptReceived
}

export class CCX_RemoteCall {
  method: string;
  url: string;

}

export class CCX_PluginsRecordHolder {
  plugin?: CCX_PluginsRecord;
  errorId?: number = 0;
  error?: string = null;
}
