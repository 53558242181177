import { DataMgr } from "./data-mgr/data-mgr";

declare global {
  interface Window { SpeechSDK, annyang }
}

export class Speech {
  private listener = SpeechListener.none;
  private speechRecognizer = null;
  private key = null;
  private region = null;
  private speechkeyword = null;
  private tickTimer = -1;
  private endTimer = -1;
  private speechConfig = null;
  private synthesizer = null;
  private audio: HTMLAudioElement = null;

  static staticSpeech = null;

  text: string = '';

  constructor() {
    if (Speech.staticSpeech === null) {
      Speech.staticSpeech = this;
    }
  }

  say(text: string, key: string, region: string, voice: string = 'en-US-AriaNeural', isOpenAI: boolean = false) {

    // if (isOpenAI) {
    //   return this.sayThruOpenAI(text, key, voice);
    // }
    // else {
    //   return this.sayThruAzure(text, key, region, voice);
    // }
  }

  sayThruOpenAI(text: string, key: string, voice: string = 'alloy') {
    let url = 'https://api.openai.com/v1/audio/speech';
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + key);
    headers.append('Content-Type', 'application/json');

    let body = JSON.stringify({
      "model": "tts-1",
      "input": text,
      "voice": voice
    });

    fetch(url, {
      method: 'POST',
      headers: headers,
      body: body
    })
      .then(response => response.blob())
      .then(blob => {
        let url = window.URL.createObjectURL(blob);
        this.audio = new Audio(url);
        this.audio.play();
        // let a = document.createElement('a');
        // a.href = url;
        // a.download = 'speech.mp3';
        // a.click();
      })
      .catch(error => console.log('Error:', error));
  }

  async sayThruOpenAIToUrl(text: string, key: string, voice: string = 'alloy') {
    let url = 'https://api.openai.com/v1/audio/speech';
    let headers = new Headers();
    headers.append('Authorization', 'Bearer ' + key);
    headers.append('Content-Type', 'application/json');

    let body = JSON.stringify({
      "model": "tts-1",
      "input": text,
      "voice": voice
    });

    var response = await fetch(url, {
      method: 'POST',
      headers: headers,
      body: body
    })
      .catch(error => console.log('Error:', error));

    var blob = await (response as any).blob();
    var urlAudio = window.URL.createObjectURL(blob);
    return urlAudio;
  }

  async sayFromUrl(url: string) {
    return new Promise((resolve, reject) => {
      this.audio = new Audio(url);
      this.audio.addEventListener('ended', resolve);
      this.audio.addEventListener('error', reject);
      this.audio.play();
    });
  }

  sayThruAzure(text: string, key: string, region: string, voice: string = 'en-US-AriaNeural') {
    this.key = key;
    this.region = region;
    this.coreInit();
    this.speechConfig.speechSynthesisVoiceName = voice;
    this.speechConfig.speechSynthesisLanguage = voice.substring(0, 5);
    this.synthesizer = new window.SpeechSDK.SpeechSynthesizer(this.speechConfig);
    this.synthesizer.speakTextAsync(
      text,
      _ => { this.synthesizer.close(); this.synthesizer = null; },
      _ => { this.synthesizer.close(); this.synthesizer = null; }
    );
  }

  buildThruSSML(text: string, key: string, region: string,
    voice: string = 'en-US-SaraNeural',
    style: string = 'chat',
    rate: string = '1.15') {
    let ssml = `    <voice name="{voice}">
    <mstts:express-as style="{style}">
    <prosody rate="{rate}">
      {text}
      </prosody>
    </mstts:express-as>
    </voice>`;

    ssml = ssml.replace('{voice}', voice).replace('{style}', style).replace("{rate}", rate).replace('{text}', text);
    return ssml;
  }

  wrapSSML(ssml: string) {
    return '<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xml:lang="en-US" xmlns:mstts="https://www.w3.org/2001/mstts">'
      + ssml +
      '</speak>';
  }

  async sayThruSSML(text: string, key: string, region: string,
    voice: string = 'en-US-SaraNeural',
    style: string = 'chat',
    rate: string = '1.15') {

    // voice = "es-MX-DaliaNeural";

    let ssml = `
<speak version="1.0" xmlns="http://www.w3.org/2001/10/synthesis" xml:lang="en-US" xmlns:mstts="https://www.w3.org/2001/mstts">
    <voice name="{voice}">
    <mstts:express-as style="{style}">
    <prosody rate="{rate}">
      {text}
      </prosody>
    </mstts:express-as>
    </voice>
</speak>`;

    ssml = ssml.replace('{voice}', voice).replace('{style}', style).replace("{rate}", rate).replace('{text}', text);
    //console.log(ssml);
    await this.sayWithSSML(ssml, key, region);
  }

  async sayWithSSML(ssml: string, key: string, region: string): Promise<void> {
    this.key = key;
    this.region = region;
    this.coreInit(); // Assuming this initializes your speechConfig

    this.synthesizer = new window.SpeechSDK.SpeechSynthesizer(this.speechConfig);
    Speech.staticSpeech.synthesizer = this.synthesizer;
    return new Promise(async (resolve, reject) => {
      await this.synthesizer.speakSsmlAsync(
        ssml,
        _ => {
          this.synthesizer.close();
          this.synthesizer = null;
          resolve();  // Resolve the promise when speech synthesis is done
        },
        error => {
          console.error("Synthesis failed:", error);
          this.synthesizer.close();
          this.synthesizer = null;
          reject(error);  // Reject the promise in case of an error
        }
      );
    });
  }

  stopSpeech() {
    const synth = this;
    //console.log(synth.synthesizer);
    if (synth.synthesizer) {
      synth.synthesizer.stopSpeakingAsync(
        _ => { synth.synthesizer.close(); synth.synthesizer = null; },
        _ => { synth.synthesizer.close(); synth.synthesizer = null; }
      );
    }
    if (this.audio) {
      this.audio.pause();
    }
  }

  start(key: string, region: string, speechkeyword: string) {
    this.key = key;
    this.region = region;
    this.speechkeyword = speechkeyword;
    this.startKeyword();
  }

  stop() {
    window.annyang.pause();
    this.listener = SpeechListener.none;
    this.gotEvent(SpeechEventType.noListening);
    this.speechRecognizer.close();
    this.speechRecognizer = null;
    window.annyang.abort();
    this.listener = SpeechListener.none;
  }

  private coreInit() {
    if (this.speechConfig === null) {
      this.speechConfig = window.SpeechSDK.SpeechConfig.fromSubscription(this.key, this.region);
      this.speechConfig.speechRecognitionLanguage = "en-US";
    }
  }

  private gotResult(text: string, type: SpeechType) {
    //console.log('speech-text:' + text);
    DataMgr.doEvent('speech-got-text', { text: text, type: type });
  }

  private gotEvent(type: SpeechEventType) {
    //console.log('speech-event:' + type);
    DataMgr.doEvent('speech-got-event', { type: type });
  }

  startAll() {
    const wasPaused = this.listener === SpeechListener.pausedAll;
    if (!wasPaused) {
      this.text = '';
    }

    if (this.listener === SpeechListener.keyword || this.listener === SpeechListener.pausedAll) {
      window.annyang.pause();
      this.listener = SpeechListener.pausedKeyword;
    }

    if (this.speechRecognizer == null) {
      this.coreInit();

      let audioConfig = window.SpeechSDK.AudioConfig.fromDefaultMicrophoneInput();
      this.speechRecognizer = new window.SpeechSDK.SpeechRecognizer(this.speechConfig, audioConfig);

      this.speechRecognizer.recognizing = (recognizer, event) => {
        //console.log("Recognition event:", event);
        if (this.tickTimer > -1) {
          window.clearTimeout(this.tickTimer);
        }
        if (this.endTimer > -1) {
          window.clearTimeout(this.endTimer);
        }

        if (event.result.text) {
          this.text = event.result.text;
          this.gotResult(event.result.text, SpeechType.interim);
        }

        this.tickTimer = window.setTimeout(() => { this.tick() }, 2500);
        this.endTimer = window.setTimeout(() => { this.endTick() }, 5000);
      };

      this.speechRecognizer.speechEndDetected = (recognizer, event) => {
        if (this.listener != SpeechListener.pausedAll) {
          this.gotEvent(SpeechEventType.allDone);
        }
      }

      this.speechRecognizer.recognized = (recognizer, event) => {
        if (event.result.text) {
          this.text = event.result.text;
          this.gotResult(event.result.text, SpeechType.complete);
        }
      }
    }

    this.tickTimer = window.setTimeout(() => { this.tick() }, 2500);
    this.speechRecognizer.startContinuousRecognitionAsync();
    this.listener = SpeechListener.all;

    if (wasPaused) {
      if (this.text.toLocaleLowerCase().endsWith('. hold on.')) {
        this.text = this.text.substring(0, this.text.length - 10);
      }
    }

    this.gotEvent(wasPaused ? SpeechEventType.allResuming : SpeechEventType.allListening);
  }

  private endTick() {
    this.speechRecognizer.stopContinuousRecognitionAsync();
    this.gotEvent(SpeechEventType.keywordListening);
    this.text = '';
    this.listener = SpeechListener.keyword;
    window.annyang.resume();
  }

  private tick() {
    var cmd = this.text.toLocaleLowerCase().replace(/\./g, '');
    if (cmd.endsWith(' restart')
      || cmd.endsWith(' erase that')
      || cmd.endsWith(' start again')
      || cmd.endsWith(' nevermind')
      || cmd.endsWith(' never mind')) {

      this.gotEvent(SpeechEventType.restartAll);

      if (this.endTimer > -1) {
        window.clearTimeout(this.endTimer);
      }

      this.endTimer = window.setTimeout(() => { this.endTick() }, 5000);
    }
    else if (cmd.endsWith(' pause')
      || cmd.endsWith(' hold on')) {
      this.gotEvent(SpeechEventType.pauseAll);
      this.listener = SpeechListener.pausedAll;
      this.speechRecognizer.stopContinuousRecognitionAsync();
      this.gotEvent(SpeechEventType.keywordListening);
      window.annyang.resume();

      if (this.endTimer > -1) {
        window.clearTimeout(this.endTimer);
      }

      this.endTimer = window.setTimeout(() => { this.endTick() }, 60000);
    }
    else {
      this.endTick();
    }
  }

  private startKeyword() {
    if (window.annyang) {
      const keyStop = this.speechkeyword + ' stop listening';
      const key = this.speechkeyword;
      var commands = {
        'stop listening': () => {
          this.stop();
        }
      };

      commands[keyStop] = () => { this.stop() };
      commands[key] = () => { window.annyang.pause(); this.startAll(); }
      this.gotEvent(SpeechEventType.keywordListening);

      if (this.listener == SpeechListener.none) {
        // Add our commands to annyang
        window.annyang.addCommands(commands);

        // Start listening. You can call this here, or attach this call to an event, button, etc.
        window.annyang.start();
        this.listener = SpeechListener.keyword;
      }
    }
  }
}

export enum SpeechListener {
  none,
  pausedKeyword,
  keyword,
  pausedAll,
  all
}

export class SpeechPart {
  text: string;
  type: SpeechType;
}

export enum SpeechType {
  interim,
  complete
}

export enum SpeechEventType {
  text,
  noListening,
  keywordListening,
  allListening,
  allResuming,
  allDone,
  restartAll,
  pauseAll
}
