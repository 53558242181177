import { Conversation, ConversationHeader, ConversationSet } from "./Model";
import { CorpSvr } from "../corpsvr";
import { eventBus } from "../event-bus";
import { XMgr } from "../x-mgr/x-mgr";
import { XAuthLocal } from "global/auth/xauth";
import { ZService } from "./ZSettingsfn";
import { fetchWithToken, postWithToken } from "global/utils";
import { CCX_HistoryMainRecord, ChatPrompt, SavedPromptX } from "global/data-mgr/data-mgr";

export abstract class ZStorage {
  savedPrompts = [];
  static storages = {};

  static getStorage(service?: ZService, convSet?: ConversationSet) {

    if (!service) {
      return new ZStorageLocal();
    }

    if (ZStorage.storages[service.uid]) {
      return ZStorage.storages[service.uid];
    }

    if (service.authInfo && service.remoteConfigURL) {
      const stor = new ZStorageServer(service, convSet);
      ZStorage.storages[service.uid] = stor;

      return ZStorage.storages[service.uid];
    }
    else {
      const stor = new ZStorageLocal();
      ZStorage.storages[service.uid] = stor;
      return stor;
    }

  }

  abstract getPrompts(): Promise<any[]>;
  abstract deletePrompt(prompt: any);
  abstract getChats(): Promise<ConversationHeader[]>;
  abstract loadChat(uid: string): Promise<Conversation | null>;
  abstract deleteChat(uid: string): Promise<void>;
  abstract refreshSaveChat(chat: Conversation): Promise<boolean>;
  abstract clearChatHistory(choice: string): Promise<void>;
  abstract saveChatlist(): void;
  abstract savePrompt(prompt: SavedPromptX): Promise<void>;
}

export class ZStorageLocal extends ZStorage {
  async getPrompts() {
    const prompts = await XMgr.instance.loadSavedPrompts();
    if (prompts) {
      const sPrompts = [];
      prompts.map(p => {
        sPrompts.push({
          userId: 0,
          promptId: 0,
          name: p.name,
          prompt: p.prompt,
          promptVisible: true,
          dataVisible: false,
          dataEditable: false,
          dataRequired: false,
          directConversation: true,
          recordRequests: false,
          recordResponses: false,
          recordData: false,
          isSharable: false
        })
      });

      this.savedPrompts = sPrompts;
    }
    else {
      this.savedPrompts = [];
    }

    return this.savedPrompts;
  }

  deletePrompt(prompt: any) {
    const prompts = XMgr.instance.savedPrompts;
    if (prompts) {
      // remove the prompt with the name (prompt.name) from prompts
      const newPrompts = prompts.filter(p => p.name !== prompt.name);
      XMgr.instance.savedPrompts = newPrompts;
      XMgr.instance.savePrompts();
    }

    this.savedPrompts = this.savedPrompts.filter(p => p !== prompt);
    eventBus.emit('ccx-instructions-loaded');
  }

  async getChats() {
    return await XMgr.instance.loadChatlist();
  }

  async loadChat(uid: string) {
    return await XMgr.instance.loadChat(uid);
  }

  async deleteChat(uid: string) {
    //await CorpSvr.deleteChat(chat);
    //eventBus.emit('new-chat');
    return await XMgr.instance.deleteChat(uid);
  }

  saveChatlist() {
    XMgr.instance.saveChatlist();
  }

  async refreshSaveChat(chat: Conversation): Promise<boolean> {
    return XMgr.instance.refreshSaveChat(chat);
  }

  async clearChatHistory(choice: string) {
    XMgr.instance.clearChatHistory(choice);
  }

  async savePrompt(prompt: SavedPromptX): Promise<void> {
    const prompts = XMgr.instance.savedPrompts;
    if (prompts) {
      prompts.push(prompt);
      XMgr.instance.savedPrompts = prompts;
      XMgr.instance.savePrompts();
    }

    this.savedPrompts.push(prompt);
    eventBus.emit('ccx-instructions-loaded');
  }
}

export class ZStorageServer extends ZStorage {
  service: ZService;
  convSet: ConversationSet;
  userName: string;
  root: string;
  chats: ConversationHeader[] = [];

  constructor(service: ZService, convSet: ConversationSet) {
    super();
    this.service = service;
    this.convSet = convSet;
    this.root = service.remoteConfigURL;
  }

  async init() {
    if (this.service.authInfo) {
      const serviceName = this.service.commType;
      var authService = this.convSet.tempStorage.auths[serviceName];
      if (!authService) {
        return false;
      }

      if (!authService.isLoggedIn()) {
        if (!(await authService.isLoggedIn())) {
          await authService.setLogged(true);
          await authService.forceLogin(async () => {
            console.log('Logged in successfully');
            window.eventBus.emit('logged-in', { name: await authService.getMyName() });
            this.convSet.tempStorage.auths[serviceName] = authService;
            console.log('Logged in as2: ' + this.userName);
          });
        }
      }

      this.userName = await authService.getMyUserName();
      return true;
    }

    return false;
  }

  async getToken() {
    const serviceName = this.service.commType;
    const auth = this.convSet.tempStorage.auths[serviceName];
    const token = await auth.getToken();
    return token;
  }

  async getPrompts() {
    if (!await this.init()) {
      return [];
    }

    const me = this.userName;
    const url = this.root + '/api/prompts?userId=' + me;
    const response = await fetchWithToken(url, {}, await this.getToken());
    if (response.ok) {
      const prompts = await response.json();
      // sort prompts by promptUpdated
      prompts.sort((a, b) => {
        if (a.promptUpdated < b.promptUpdated) {
          return 1;
        } else if (a.promptUpdated > b.promptUpdated) {
          return -1;
        } else {
          return 0;
        }
      });

      return prompts;
    } else {
      console.error('Error fetching prompts:', response.statusText);
    }

    return [];
  }

  async deletePrompt(prompt: any) {
    if (!await this.init()) {
      return null;
    }

    const me = this.userName;
    const url = this.root + '/api/prompts/delete?userId=' + me + '&promptid=' + prompt.promptId;
    const response = await fetchWithToken(url, {}, await this.getToken());
    if (response.ok) {
      eventBus.emit('ccx-instructions-loaded');
      return { errorId: 0 };
    } else {
      console.error('Error deleting prompt:', response.statusText);
      return { error: response.statusText, errorId: -1 };
    }
  }

  async getChats() {
    if (!await this.init()) {
      return [];
    }

    const me = this.userName;
    const url = this.root + '/api/history/list?userId=' + me;
    const response = await fetchWithToken(url, {}, await this.getToken());
    if (response.ok) {
      const chats = await response.json();
      this.chats.map(c => {
        const existing = chats.find(ch => ch.uid === c.uid);
        if (existing) {
          existing.isSelected = c.isSelected;
        }
      });

      this.chats = chats;
      return chats;
    } else {
      console.error('Error fetching chats:', response.statusText);
    }

    return [];
  }

  async loadChat(chatId: string) {
    if (!await this.init()) {
      return null;
    }

    const conv = new Conversation();

    const me = this.userName;
    const url = this.root + '/api/history/chat?userId=' + me + '&chatId=' + chatId;
    const response = await fetchWithToken(url, {}, await this.getToken());
    if (response.ok) {
      const history = await response.json();
      const conv = Conversation.fromServerChat(history);
      return conv;
      // return { history: history, errorId: 0 };
    } else {
      console.error('Error fetching history:', response.statusText);
      const conv = Conversation.fromError({ text: response.statusText, id: -1 });
      return conv;
    }
  }

  async deleteChat(uid: string) {
    if (!await this.init()) {
      return;
    }

    const me = this.userName;
    const url = this.root + '/api/history/delete?userId=' + me + '&chatId=' + uid;
    const response = await fetchWithToken(url, {}, await this.getToken());
    if (response.ok) {
      return; // { errorId: 0 };
    } else {
      console.error('Error deleting prompt:', response.statusText);
      return;
    }
  }

  async refreshSaveChat(chat: Conversation): Promise<boolean> {
    if (!await this.init()) {
      return false;
    }

    const me = this.userName;
    const svrChat = chat.toServerChat();
    const lastChat = svrChat.length > 0 ? svrChat[svrChat.length - 1] : null;

    const url = this.root + '/api/history/additem?userId=' + me + '&chatId=' + chat.uid;
    const response = await postWithToken(url, lastChat, {}, await this.getToken());
    if (response.ok) {
      console.log('saved');
      const savedRec = await response.json();
      eventBus.emit('history-item-saved', savedRec);
    } else {
      console.error('Error saving history list:', response.statusText);
    }

    return true;
  }

  async clearChatHistory(choice: string) {
    if (!await this.init()) {
      return;
    }

    const me = this.userName;
    var url = this.root + '/api/history/deleteallhistory?userId=' + me;

    const list = [];

    if (choice === 'clearSelections') {
      this.chats.filter(c => c.isSelected).map(c => {
        list.push((c as any).chatId);
      });
    } else if (choice === 'clearNonStarred') {
      this.chats.filter(c => !c.isStarred).map(c => {
        list.push((c as any).chatId);
      });
    }

    const response = await postWithToken(url, choice === 'clearAll' ? null : list, {}, await this.getToken());
    if (response.ok) {
      return; // { errorId: 0 };
    } else {
      console.error('Error deleting chat list:', response.statusText);
      return;
    }
  }

  async savePrompt(prompt: SavedPromptX): Promise<void> {
    if (!await this.init()) {
      return;
    }

    const me = this.userName;
    const url = this.root + '/api/prompts/save?userId=' + me;
    const newPrompt = new ChatPrompt();
    newPrompt.name = prompt.name;
    newPrompt.prompt = prompt.prompt;
    newPrompt.promptVisible = true;

    const response = await postWithToken(url, newPrompt, {}, await this.getToken());
    if (response.ok) {
      console.log('saved');
      eventBus.emit('ccx-instructions-loaded');
    } else {
      console.error('Error saving prompts:', response.statusText);
    }
  }

  saveChatlist() {
    // new
  }
}
