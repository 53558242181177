import { Comm } from "./Comm";
import { Model } from "./Model";
import { OpenAIComm } from "./OpenAIComm";
import { ZModel, ZService } from "./ZSettingsfn";

export class DirectOpenAIComm extends OpenAIComm {
  constructor(name: string, urlBase: string, model: string, key: string) {
    super(name, urlBase, model, key);
  }

  async getModelsClass(service: ZService = null): Promise<Model[]> {
    // base implementation
    console.warn('getModelsClass not implemented:' + service.commType);
    const models: Model[] = [];
    this.addInServiceModels(models, service);
    models.map(m => {
      m.serviceUid = service.uid;
    });
    return models;
  }
}
