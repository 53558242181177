import { Comm } from "./Comm";
import { Model } from "./Model";
import { OpenAIComm } from "./OpenAIComm";
import { ZModel, ZService } from "./ZSettingsfn";

export class GroqAIComm extends OpenAIComm {
  constructor(name: string, urlBase: string, model: string, key: string) {
    super(name, urlBase, model, key);
  }

  async getModelsClass(service: ZService = null): Promise<Model[]> {
    if (this.wasCalled) {
      return service ? service.models : [];
    }

    this.wasCalled = true;
    const json = await Comm.getAllModelsJson();
    this.keyUrl = json.groq.keyUrl;
    const models = Model.loadModelsFlat(json.groq, this, service);
    models.map(m => {
      m.serviceUid = service.uid;
    });
    // this.addInServiceModels(models, service);
    this.isConnected = true;
    return models;
  }
}
