import { createParser } from "eventsource-parser";
import { AIComm } from "./AIComm";
import { ChatSettingsInferenceTemplate, InferenceParams, Message, Model, ModelSets, TextReply } from "./Model";
import { ZService, ZServiceTemplate, ZSettings } from "./ZSettingsfn";
import { Comm } from "./Comm";
import { XAuthLocal } from "global/auth/xauth";
import { XServices } from "global/services";
import { getBrowserInfo } from "global/utils";
import { eventBus } from "global/event-bus";

declare global {
  //interface Window { ai }
}

export class BrowserComm extends AIComm {
  isJson: boolean;
  token = '';
  session: any = null;

  constructor(name, urlBase, model, key, isJson = true) {
    super(name, urlBase, model, key);
    this.isJson = isJson;
  }

  async getAuthHeader(model: Model = null): Promise<{ key: string; value: string }> {
    return null;
  }

  getURLwithSuffix(url, model) {
    if (url.endsWith('/')) {
      url = url.substring(0, url.length - 1);
    }

    url = 'js:window.ai';

    return url;
  }

  getValueByPath(data, path) {
    let result = data;
    for (const key of path) {
      if (result != null && key in result) {
        result = result[key];
      } else {
        return undefined; // Path does not exist
      }
    }
    return result;
  }

  async getModelsClass(service: ZService = null): Promise<Model[]> {
    if (this.wasCalled || !ModelSets.instance) {
      return service ? service.models : [];
    }

    this.wasCalled = true;
    const json = this.browserModels() as any; // await Comm.getAllModelsJson();
    const models = Model.loadModelsFlat(json.browser, this, service);
    models.map(m => {
      m.serviceUid = service.uid;
    });
    // this.addInServiceModels(models, service);
    this.isConnected = true;

    service.useApiKey = false;
    service.aiTemplate = 'promptapi';
    const browser = getBrowserInfo();
    if (browser === 'Chrome') {
      this.apiKey = 'nano';
    }

    return models;
  }

  static async addDefaults(zsettings: ZSettings, force: boolean = false, templates: ZServiceTemplate[] = null) {
    if (!(window as any).ai) {
      return;
    }

    const services = zsettings.services;
    var bc = services.find(s => s.commType === 'browser');
    const availableModels = BrowserComm.sBrowserModels();
    const model = availableModels['browser'].data[0];
    model.isSelected = true;
    model.serviceUid = bc.uid;
    window.setTimeout(async ()=>{
      //debugger;
      let coreModel = bc.models.find(m => m.id === 'window.ai' || m.id === 'nano');
      if (coreModel) {
        coreModel.isSelected = true;
        coreModel.serviceUid = bc.uid;
      }
      await eventBus.emit('select-model', { chatId: 'chat', model: model, isSelected: true, isShift: false, serviceUid: bc.uid });
    },500)
  }

  browserModels() {
    return BrowserComm.sBrowserModels();
  }

  static sBrowserModels() {
    if (!(window as any).ai) {
      return {};
    }

    const models = {
      "browser": {
        "keyUrl": "",
        "data": []
      }
    };

    const browser = getBrowserInfo();
    if (browser === 'Chrome') {
      models['browser'].data.push({
        "id": "nano",
        "object": "model",
        "created": 1693721698,
        "owned_by": "Google",
        "active": true,
        "context_window": 4096
      });
    } else {
      models['browser'].data.push({
        "id": "window.ai",
        "object": "model",
        "created": 1693721698,
        "owned_by": "",
        "active": true,
        "context_window": 4096
      });
    }

    return models;

  }

  async prepSession() {
    if (this.session === null) {
      this.session = await (window as any).ai.createTextSession({
        // Handle partial results if they can be streamed in
        onStreamResult: (res) => console.log(res.message.content)
      });
    }
  }

  async callDirect(instruction: string, infTemp: ChatSettingsInferenceTemplate | null, model: Model): Promise<string> {
    const useTemplate = false;

    await this.prepSession();

    const prompt = useTemplate ? '<|user|>' + instruction + '<|assistant|>' : instruction;
    const text = await (window as any).ai.prompt(prompt);
    return text;
  }

  //Upcall:01
  async call(instruction: string, msgs: Message[], infTemp: ChatSettingsInferenceTemplate | null, temperature: number, update: any, model: Model, chatId: string = null): Promise<string> {
    //await this.prepSession();

    if ((!ModelSets.aiTemplates) || ModelSets.aiTemplates == null) {
      await ModelSets.postLoad();
    }

    const service = ModelSets.getService(model);
    const browser = getBrowserInfo();
    const defaultTemplate = browser != 'Chrome' ? 'phi3-mini' : 'none';
    const templateName = infTemp && infTemp.template != 'default' ? infTemp.template : defaultTemplate;
    const hasTemplate = templateName !== 'none';
    const template = await ModelSets.formInferenceTemplate(infTemp, defaultTemplate);
    //debugger;
    const newMessages = msgs.map(m => {
      return {
        role: m.type,
        type: 'text',
        text: m.message
      };
    });

    if (instruction) {
      newMessages.unshift({ role: "system", type: 'text', text: instruction });
    }

    //var initAndUser = this.getInitAndUser(newMessages, service.aiTemplate);
    //debugger;

    var prompt = '';

    newMessages.map(m => {
      switch(m.role) {
        case 'system':
          prompt += template.preInst + m.text + template.postInst;
          break;
        case 'user':
          prompt += template.preUser + m.text + template.postUser;
          break;
        case 'assistant':
          prompt += template.preAsst + m.text + template.postAsst;
          break;
      }

      if (!hasTemplate) {
        prompt += '\n';
      }
    });

    prompt += template.leadingAsst;

    try {
      const startTime = new Date().getTime();
      var prevData = { len: 0 };
      //console.log(prompt);
      const sess = await (window as any).ai.createTextSession();

      console.log('--------------------------------');
      console.log('prompt:'+prompt);
      const response = sess.promptStreaming(prompt);
      console.log('response:'+prompt);

      //const response = await sess.prompt(prompt);
      //console.log(response);
      //await this.parseAndSend2(response, update, model, startTime, prevData);

      for await (const chunk of await response) {
        //console.log(chunk);
        await this.parseAndSend2(chunk, update, model, startTime, prevData);
      }

      console.log("Sent data");
    } catch (error) {
      const txtResp = TextReply.create(error.message, true);
      update(txtResp, model, true);
      eventBus.emit('ccx-error', { error: error.message, isDone: true, uid: chatId });
      console.error("Failed to open WebSocket connection: ", error);
    }
    return "";
  }

  sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  async parseAndSend2(response, update, model, startTime = 0, prevData: any = null): Promise<boolean> {
    var doContinue = true;

    const parser = (response) => {
      var resp = response;
      if (prevData && prevData.len && prevData.len <= resp.length) {
        resp = resp.substring(prevData.len);
      }
      prevData.len += resp.length;
      if (resp) {
        const endTime = new Date().getTime();
        const ms = endTime - startTime;

        const textChunk = resp;

        const reply = { text: textChunk, isDone: false, ms: ms };
        if (reply) {
          // console.log(reply);
          doContinue = update(reply, model);
        }
      }
    };

    //if (response) {
    parser(response);

    if (!doContinue) {
      const endTime = new Date().getTime();
      const ms = endTime - startTime;
      update({ isDone: true, ms: ms }, model)
    }
    //}

    return doContinue;
  }

  parseTextReply(text: string, model: Model): TextReply {
    return { text: text, isDone: false };
  }

}
