import { eventBus } from "global/event-bus";
import { AIComm } from "./AIComm";
import { Comm } from "./Comm";
import { ChatSettingsInferenceTemplate, InferenceParams, Message, Model, TextReply } from "./Model";
import { UrlCache } from "./UrlCache";
import { ZModel, ZService } from "./ZSettingsfn";

export class OpenAIComm extends AIComm {
  constructor(name: string, urlBase: string, model: string, key: string) {
    super(name, urlBase, model, key);
    this.canHandleSpecialAttachments = true;
  }

  async getModelsFresh() {
    if (!this.apiKey) {
      return [];
    }

    const url = this.apiURL + '/models';
    const auth = await this.getAuthHeader();
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application json',
        [auth.key]: auth.value
      }
    });

    const data = await response.json();
    return data;
  }

  async getModelsClass(service: ZService = null): Promise<Model[]> {
    if (this.wasCalled) {
      return service ? service.models : [];
    }

    this.wasCalled = true;
    //ZService.debug(service, 'before:getModelsClass');
    // get fresh list using urlcache at 60 seconds
    // const freshModels = UrlCache.getSet('openai-models', () => this.getModelsFresh(), 60);
    const json = await Comm.getAllModelsJson();
    this.keyUrl = json.openai.keyUrl;
    const models = Model.loadModels(json.openrouter, this);
    const openAIModels = models.filter(m => m.id.startsWith("openai/"));
    openAIModels.map(m => {
      m.id = m.id.substring(7);
      m.serviceUid = service.uid;
    });

    const freshModels = await UrlCache.getSet('openai-models', async () => await this.getModelsFresh(), 60);
    if (freshModels && freshModels.data && freshModels.data.length > 0) {
      freshModels.data.map(item => {
        if (openAIModels.findIndex(x => x.id === item.id) < 0) {
          // new model
          const model = Model.construct3(item.id, item.id, 0);
          model.owner = 'openai';
          var mod = 'text';
          if (item.id.indexOf('tts') > -1) {
            mod = 'voice';
          } else if (item.id.indexOf('image') > -1) {
            mod = 'image';
          } else if (item.id.indexOf('whisper') > -1) {
            mod = 'audio';
          } else if (item.id.indexOf('embed') > -1) {
            mod = 'embedding';
          }
          model.architecture = { modality: mod, tokenizer: 'gpt4', instruct_type: 'instruct' };
          model.comm = this;
          model.serviceUid = service.uid;
          openAIModels.push(model);
        }
      });
    }

    // this.addInServiceModels(openAIModels, service);
    console.log('openai models:' + openAIModels.length);
    this.isConnected = true;

    // default
    // if all models are unselected, choose gpt4o as selected
    if (openAIModels.filter(m => m.isSelected).length === 0) {
      const gpt4o = openAIModels.find(m => m.id.indexOf('gpt4o') > -1);
      if (gpt4o) {
        gpt4o.isSelected = true;
      }
    }

    return openAIModels;
    // if (!this.apiKey) {
    //     return [];
    // }

    // const url = this.apiURL + '/models';
    // const auth = this.getAuthHeader();
    // const response = await fetch(url, {
    //     method: 'GET',
    //     headers: {
    //         'Content-Type': 'application json',
    //         [auth.key]: auth.value
    //     }
    // });

    // const data = await response.json();
    // return data;

  }

  async getAuthHeader(model: Model = null): Promise<{ key: string; value: string }> {
    const key = 'Authorization';
    const value = `Bearer ${this.apiKey}`;
    return { key, value };
  }

  getURLwithSuffix(url, model) {
    // if url has / on end, remove it
    if (url.endsWith('/')) {
      url = url.substring(0, url.length - 1);
    }

    if (model.id.indexOf('tts') > -1) {
      return url + '/audio/speech';
    }

    if (model.id.indexOf('whisper') > -1) {
      return url + '/audio/transcriptions';
    }

    return url + '/chat/completions';
  }

  fixModelId(id) {
    if (this.name === 'groq') {
      return id.replace('groq/', '');
    }

    return id;
  }

  async callDirect(instruction: string, infTemp: ChatSettingsInferenceTemplate | null, model: Model): Promise<string> {
    const data = {
      model: this.fixModelId(model.id),
      stream: false,
      messages: [
        { role: 'system', content: instruction }
      ],
    };

    const maxTokens = infTemp.inference?.max_tokens ?? 0;
    if (maxTokens > 0) {
      data['max_tokens'] = maxTokens;
    }

    const url = this.getURLwithSuffix(this.apiURL, model);
    const auth = await this.getAuthHeader(model);
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        [auth.key]: auth.value
      },
      body: JSON.stringify(data)
    });

    const text = await response.text();
    return text;
  }

  async callTTS(text: string, key: string, voiceModel: Model, voice: string = 'alloy'): Promise<string | void> {
    const url = this.getURLwithSuffix(this.apiURL, voiceModel);
    const auth = await this.getAuthHeader(voiceModel);

    let body = JSON.stringify({
      "model": voiceModel.id,
      "input": text,
      "voice": voice
    });

    return await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        [auth.key]: auth.value
      },
      body: body
    })
      .then(response => response.blob())
      .then(blob => {
        let url = window.URL.createObjectURL(blob);
        return url;
      })
      .catch(error => console.log('Error:', error));
  }

  async callListen(blob: Blob, listenModel: Model, convId: string, mode: string) {
    const url = this.getURLwithSuffix(this.apiURL, listenModel);
    const auth = await this.getAuthHeader(listenModel);

    const file = new File([blob], 'recording.ogg', { type: 'audio/ogg; codecs=opus' });
    const data = new FormData();
    data.append('model', 'whisper-1');
    data.append('file', file);

    return await fetch(url, {
      method: 'POST',
      headers: {
        [auth.key]: auth.value
      },
      body: data as any
    })
      .then(response => response.json())
      .then(responseData => {
        eventBus.emit('gotSpeechText', { text: responseData.text, isError: false, convId: convId, mode: mode })
      })
      .catch(error => {
        console.error(error);
        eventBus.emit('gotSpeechText', { text: '', isError: true, error: error, convId: convId, mode: mode });
      });
  }

  // async sayThruOpenAIToUrl(text: string, key: string, voice: string = 'alloy') {
  //   let url = 'https://api.openai.com/v1/audio/speech';
  //   let headers = new Headers();
  //   headers.append('Authorization', 'Bearer ' + key);
  //   headers.append('Content-Type', 'application/json');

  //   let body = JSON.stringify({
  //     "model": "tts-1",
  //     "input": text,
  //     "voice": voice
  //   });

  //   var response = await fetch(url, {
  //     method: 'POST',
  //     headers: headers,
  //     body: body
  //   })
  //     .catch(error => console.log('Error:', error));

  //   var blob = await (response as any).blob();
  //   var urlAudio = window.URL.createObjectURL(blob);
  //   return urlAudio;
  // }

  parseTextReply(text: string, model: Model, ms = 0): TextReply {
    const data = JSON.parse(text);
    if (data.choices[0]?.delta?.content) {
      return { text: data.choices[0].delta.content, isDone: false, ms: ms };
    }
    if (data.choices[0]?.message?.content) {
      return { text: data.choices[0].message.content, isDone: false, ms: ms };
    }

    return null;
  }

  readFileAsBase64(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const binaryString = reader.result as string;
        const base64String = btoa(binaryString);
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsBinaryString(file);
    });
  }

  //imgs:06
  async call(instruction: string, msgs: Message[], infTemp: ChatSettingsInferenceTemplate | null, temperature: number, update: any, model: Model, chatId: string = null): Promise<string> {
    // if voice model, don't submit
    if (model.architecture.modality.indexOf('voice')>-1) {
      return "";
    }

    const messages = [];
    for (let m of msgs) {
      var content: any = m.message;
      if ((m.images && m.images.length > 0) || (m.files && m.files.length > 0)) {
        content = [
          {
            type: "text",
            text: m.message
          }
        ];

        if (m.images && m.images.length > 0) {
          for (let img of m.images) {
            content.push({
              type: "image_url",
              image_url: {
                url: img
              }
            });
          }
        }

        if (m.files && m.files.length > 0) {
          for (let file of m.files) {
            const base64String = file.contentBase64;
            const json = {
              file: file.name,
              encoded: 'base64',
              micro_instruction: 'Using the attached file, respond directly USING the content as if it were ALREADY provided in plain text, without mentioning the encoding or decoding process. Answer questions using the content, not replying with the content, unless asked.', content: base64String
            };
            content.push({
              type: "text",
              text: JSON.stringify(json)
            });
          }
        }

        if (!this.canHandleSpecialAttachments) {
          content = JSON.stringify(content);
        }
      }
      messages.push({
        role: m.type,
        content: content
      });
    }

    const data = {
      model: this.fixModelId(model.id),
      messages: messages,
      stream: true,
      temperature: temperature
    };

    const maxTokens = infTemp.inference?.max_tokens ?? 0;
    if (maxTokens > 0) {
      data['max_tokens'] = maxTokens;
    }

    if (instruction) {
      //if (this.model.indexOf('-3.5') > 0 || this.model.indexOf('-35') > 0) {
      // insert instruction into data.messages as first element
      data.messages.unshift({ role: "system", content: instruction });
      //}
    }

    console.log('SUB TO MODEL:' + model.id + ":");
    console.log(data);

    const url = this.getURLwithSuffix(this.apiURL, model); //this.fixModelId(model.id));
    const auth = await this.getAuthHeader(model);
    const startTime = new Date().getTime();

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        [auth.key]: auth.value
      },
      body: JSON.stringify(data)
    });

    await this.parseAndSend(response, update, model, startTime);

    return "";
  }
}
