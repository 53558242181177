export class Storage {
  static get(key: string, defaultValue: any) {
    let value = localStorage.getItem(key);
    if (value === null) {
      return defaultValue;
    }
    return value;
  }

  static set(key: string, value: any) {
    localStorage.setItem(key, value);
  }
}
