import { XAuth } from "./auth/xauth";


export async function fetchWithToken(endpoint, options: any = {}, tokenX = null) {
  const token = tokenX ?? await XAuth.getToken();
  const bearer = `Bearer ${token}`;
  if (options.headers) {
    options.headers['Authorization'] = bearer;
  } else {
    options.headers = {
      Authorization: bearer
    };
  }
  if (!options.credentials) {
    // required for CORS as any request with Authorization header is a "non-simple" request
    // options.credentials = 'include';
  }

  try {
    const response = await fetch(endpoint, options);
    if (!response.ok && response.status >= 400) {
      console.error(`HTTP error! status: ${response.status}`);
    }
    return response;
  } catch (error) {
    console.error('Error in fetchWithToken:', error);
    throw error; // Ensure the error is rethrown
  }

  // return await fetch(endpoint, options).then(resp => { checkCall(resp); return resp; }).catch(err => {
  //   return Promise.reject(err);
  // });
}

export async function checkCall(resp: any) {
  if (resp.status >= 400) {
    console.error(resp);
  }
}

export function getQueryParam(name: string) {
  return new URLSearchParams(window.location.search).get(name);
}

export async function postWithToken(endpoint, data, options: any = {}, tokenX = null) {
  const token = tokenX ?? await XAuth.getToken();
  const bearer = `Bearer ${token}`;

  if (options.headers) {
    options.headers['Authorization'] = bearer;
    options.headers['Content-Type'] = 'application/json';
  } else {
    options.headers = {
      Authorization: bearer,
      'Content-Type': 'application/json',
    };
  }

  if (!options.credentials) {
    // required for CORS as any request with Authorization header is a "non-simple" request
    // options.credentials = 'include';
  }

  options.method = 'POST';
  options.body = JSON.stringify(data);
  console.log(options.body);

  return await fetch(endpoint, options)
    .then((resp) => {
      checkCall(resp);
      return resp;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
}

export function getBrowserInfo() {
  const userAgent = navigator.userAgent;

  // Check for Edge (Chromium)
  const isEdgeChromium = userAgent.includes("Edg/");
  if (isEdgeChromium) {
    return "Edge (Chromium)";
  }

  // Check for Chrome
  const isChrome = userAgent.includes("Chrome/") && !userAgent.includes("Edg/");
  if (isChrome) {
    return "Chrome";
  }

  // Return "Other" if the browser is neither Edge (Chromium) nor Chrome
  return "Other";
}

export function toLocalDateString(utcDate) {
  if (!utcDate) {
    return "";
  }

  let localDate = new Date(utcDate + 'Z');

  // Format the local date to 'yyyy-mm-dd hh:mm'
  let year = localDate.getFullYear();
  let month = ("0" + (localDate.getMonth() + 1)).slice(-2); // Months are 0 based. Add leading 0 and slice last 2 characters
  let day = ("0" + localDate.getDate()).slice(-2); // Add leading 0 and slice last 2 characters
  let hours = ("0" + localDate.getHours()).slice(-2); // Add leading 0 and slice last 2 characters
  let minutes = ("0" + localDate.getMinutes()).slice(-2); // Add leading 0 and slice last 2 characters

  let formattedLocalDate = `${year}-${month}-${day} ${hours}:${minutes}`;

  return formattedLocalDate;
}

export function generateGUID() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
    return v.toString(16);
  });
}

export function generateUID(count: number) {
  return 'x'.repeat(count).replace(/[x]/g, function () {
    var r = Math.random() * 16 | 0, v = r;
    return v.toString(16);
  });
}
